import request from '@/utils/requestEhpc'
const baseUrl = 'service-bare-provider'
// /v1/desktop/machine/applyMachine 申领机器
export function applyMachine(data) {
  return request({
    url: baseUrl + '/v1/desktop/machine/applyMachine',
    method: 'post',
    data
  })
}
// /v1/desktop/machine/getImageList
// 获取镜像列表
export function getImageList() {
  return request({
    url: baseUrl + '/v1/desktop/machine/getImageList',
    method: 'get'
  })
}
// /v1/desktop/machine/getMachineDetail
// 根据systemId查询机器详情
export function getMachineDetail(systemId) {
  return request({
    url: baseUrl + '/v1/machine/getUserMachineDetail?systemId=' + systemId,
    method: 'get'
  })
}
// /v1/desktop/machine/liberateMachine/{systemId}
// 个人释放机器
export function liberateMachine(systemId) {
  return request({
    url: baseUrl + '/v1/machineUser/userReleaseMachine/' + systemId,
    method: 'delete'
  })
}
// /v1/desktop/machine/queryAccountMachine
// 用户实例列表
export function queryAccountMachine() {
  return request({
    url: baseUrl + '/v1/machine/queryAccountMachine',
    method: 'get'
  })
}
// /v1/desktop/machine/queryAllMachinesByTag
// 查询某tag下的所有机器
export function queryAllMachinesByTag(tagName) {
  return request({
    url: baseUrl + '/v1/desktop/machine/queryAllMachinesByTag?tag=' + tagName,
    method: 'get'
  })
}
// /v1/desktop/machine/queryAllTags
// 查询所有tags
export function queryAllTags() {
  return request({
    url: baseUrl + '/v1/desktop/machine/queryAllTags',
    method: 'get'
  })
}
// /v1/desktop/machine/queryUsableMachinesByTag
// 查询某tag下的可以申请的机器
export function queryUsableMachinesByTag(tagName) {
  return request({
    url: baseUrl + '/v1/desktop/machine/queryUsableMachinesByTag?tag=' + tagName,
    method: 'get'
  })
}
// 根据镜像id获取模板
export function getTemplateByImageId(imageId) {
  return request({
    url: baseUrl + '/v1/desktop/machine/queryTemplatesByImageId/' + imageId,
    method: 'get'
  })
}
// 查询某节点得cpu利用率
export function getCpuUsage(params) {
  return request({
    url: baseUrl + '/v1/monitor/cpuUsed',
    method: 'get',
    params
  })
}
// 查询某节点得磁盘利用率
export function getDiskUsage(params) {
  return request({
    url: baseUrl + '/v1/monitor/diskUsed',
    method: 'get',
    params
  })
}

// 查询某节点得内存利用率
export function getMemoryUsage(params) {
  return request({
    url: baseUrl + '/v1/monitor/memoryUsed',
    method: 'get',
    params
  })
}

// 查询某节点的网络接收速率和发送速率
export function getNetRecv(params) {
  return request({
    url: baseUrl + '/v1/monitor/netRate',
    method: 'get',
    params
  })
}
// 添加端口映射
export function addPortMapping(data) {
  return request({
    url: baseUrl + '/v1/ports',
    method: 'post',
    data
  })
}
// 查询自己的端口映射
export function queryPortMapping(pagenum,
  pagesize,
  sortBy,
  orderBy,
  keyWord) {
  return request({
    url: baseUrl + '/v1/ports/byself',
    method: 'get',
    params: {
      pagenum,
      pagesize,
      sortBy,
      orderBy,
      keyWord
    }
  })
}
// 添加端口映射
export function AddPortMapping(data) {
  return request({
    url: baseUrl + '/v1/ports/',
    method: 'post',
    data
  })
}

// 重置密码
export function resetPasswords(data) {
  return request({
    url: baseUrl + '/v1/machine/resetPasswords',
    method: 'post',
    data
  })
}

// 获取重置密码验证码
export function getResetPasswordCode() {
  return request({
    url: baseUrl + '/v1/machine/getPhoneVerificationCode',
    method: 'get'
  })
}

// 释放端口映射
export function releasePortMapping(id) {
  return request({
    url: baseUrl + '/v1/ports',
    method: 'patch',
    params: {
      id
    }
  })
}
