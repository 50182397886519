<template>
  <div class="card-view bare-metal-port">
    <div class="control-nav">
      <div class="search">
        <el-input
          v-model="searchValue"
          placeholder="输入关键字搜索"
          clearable
          suffix-icon="el-icon-search"
          @input="search"
        />
      </div>
    </div>
    <el-table
      ref="table"
      v-loading="tableLoading"
      :data="data"
      style="width: 100%"
      row-class-name="table-row"
      cell-class-name="table-cell"
      header-row-class-name="table-header"
      header-cell-class-name="table-header-cell"
      highlight-selection-row
      @sort-change="onSortChange"
    >
      <el-table-column prop="publicIP" label="公网ip地址" show-overflow-tooltip />
      <el-table-column prop="privateIP" label="服务器IP地址" show-overflow-tooltip />
      <el-table-column prop="publicPort" label="公网端口" show-overflow-tooltip />
      <el-table-column prop="privatePort" label="实例端口" sortable="custom" show-overflow-tooltip />
      <el-table-column prop="protocol" label="协议" sortable="custom" show-overflow-tooltip />
      <el-table-column prop="hostname" label="所属机器" show-overflow-tooltip />
      <el-table-column prop="createTime" label="创建时间" sortable="custom" show-overflow-tooltip>
        <template #default="scope">
          {{
            // moment(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss')
            formatDate(scope.row.createTime)
          }}

        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" size="mini" @click="handleRelease(scope.row.id)">释放</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="pageNum"
      :page-sizes="[10, 50, 100, 500]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
import {
  queryPortMapping, releasePortMapping
} from '@/api/bareMetal'
import { ElMessage, ElMessageBox } from 'element-plus'
import moment from 'moment'
export default {
  name: 'BareMetalPort',
  data() {
    return {
      data: [],
      tableLoading: false,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      systemId: '',
      searchValue: '',
      orderValue: '',
      sortValue: '',
      timer: null

    }
  },
  created() {
    this.getPortMapList()
  },
  methods: {
    search() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.pageNum = 1
        this.getPortMapList()
      }, 500)
    },
    getPortMapList() {
      this.tableLoading = true
      queryPortMapping(this.pageNum, this.pageSize, this.sortValue, this.orderValue, this.searchValue).then((res) => {
        if (res.meta.status === 200) {
          this.data = res.data.content
          this.total = res.data.totalelements
        } else {
          ElMessage.error(res.meta.msg)
        }
        this.tableLoading = false
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getPortMapList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getPortMapList()
    },
    // 计算总页数，确保当前页不大于总页数
    totalPage() {
      const totalPage = Math.ceil((this.total - 1) / this.pageSize)
      this.pageNum = this.pageNum > totalPage ? totalPage : this.pageNum
      this.pageNum = this.pageNum < 1 ? 1 : this.pageNum
    },
    onSortChange({ column, prop, order }) {
      const data = {
        sortBy: order ? prop : '',
        orderBy: order === 'ascending' ? 'ASC' : (order === 'descending' ? 'DESC' : '')
      }
      this.sortValue = data.sortBy
      this.orderValue = data.orderBy
      // 调接口
      this.getPortMapList()
    },
    handleRelease(id) {
      ElMessageBox.confirm('确认释放该端口映射？').then(() => {
        releasePortMapping(id).then((res) => {
          if (res.meta.status === 201) {
            ElMessage.success('端口映射释放成功')
            this.totalPage()
            this.getPortMapList()
          } else {
            ElMessage.error(res.meta.msg)
          }
        })
      }).catch(() => {})
    },
    // 格式化时间
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style lang="less">
.bare-metal-port {
  padding: 24px 30px;
  .icon{
    background-image: none !important;
  }
  .el-icon{
    font-size: 12px !important;
  }
  .el-table {
    margin-bottom: 16px;
  }
  .table-row {
    background-color: #fff !important;
  }
  .el-table .cell {
    line-height: 24px !important;
    color: #303133 !important;
    font-weight: 400;
    text-align: center;
  }
  .table-header-cell {
    font-weight: 400;
    font-size: 16px;
    color: #303133;
    line-height: 24px;
    border: none !important;
  }
  .el-pagination .btn-next .el-icon,
  .el-pagination .btn-prev .el-icon {
    color: rgba(0, 0, 0, 0.26) !important;
  }
  .el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
    background-color: #165dff !important;
  }
  .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
    background: #fff !important;
    border: 1px solid #d9d9d9 !important;
  }
  .el-pagination.is-background .btn-next:disabled, .el-pagination.is-background .btn-prev:disabled{
    background: #cccdce !important;
  }
  .el-button {
    background-color: #fff !important;
    border: 1px solid #165dff !important;
    color: #165dff !important;
  }
  .el-button--primary {
    background-color: #165dff !important;
    border: 1px solid #165dff !important;
    color: #fff !important;
  }
  .el-button--text{
    // border: none !important;
    background-color: transparent !important;
  }

  .control-nav {
    margin-bottom: 16px;
  }
}
</style>
